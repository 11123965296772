<script setup>
import { useWindowSize, breakpointsTailwind, useBreakpoints } from '@vueuse/core'

import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import { Link } from '@inertiajs/vue3';

import { useDark, useToggle } from '@vueuse/core'

const isDark = useDark(false)
const breakpoints = useBreakpoints(breakpointsTailwind)
const { width, height } = useWindowSize()

const current = breakpoints.current()
const active = breakpoints.active()
const xs = breakpoints.smaller('sm')
const xse = breakpoints.smallerOrEqual('sm')
const sm = breakpoints.between('sm', 'md')
const md = breakpoints.between('md', 'lg')
const lg = breakpoints.between('lg', 'xl')
const xl = breakpoints.between('xl', '2xl')
const xxl = breakpoints['2xl']
</script>

<template>
    <main class="min-h-screen bg-white dark:bg-primary-950 relative">
        <div class=""></div>
        <div class="grid sm:grid-cols-7 z-10">
            <div class="sm:col-span-3 p-5 2xl:p-10" style="background-image: url(https://cdn.fun.paris/imageMPP/paris-eiffel-riverseine.jpg); background-size: cover; background-position: top;">
                <Link :href="route('dashboard')"><img src="../../images/monpetiparis-logo.png" alt="MPP - Mon Petit Paris" class="w-2/4 mx-auto sm:w-1/4 sm:m-0" /></Link>
            </div>
            <div class="sm:col-span-4 sm:h-screen flex w-full items-center sm:shadow-md">
                <slot />
            </div>
        </div>
    </main>
    
    <div class="fixed bottom-5 left-2 bg-black text-white dark:bg-white dark:text-black px-2 py-1 rounded-md">
        <span v-if="xs" class="font-mono">XS {{ width }} X {{ height }}</span>
        <span v-if="sm" class="font-mono">SM {{ width }} X {{ height }}</span>
        <span v-if="md" class="font-mono">MD {{ width }} X {{ height }}</span>
        <span v-if="lg" class="font-mono">LG {{ width }} X {{ height }}</span>
        <span v-if="xl" class="font-mono">XL {{ width }} X {{ height }}</span>
        <span v-if="xxl" class="font-mono">XXL {{ width }} X {{ height }}</span>
    </div>
</template>
